import React from "react"
import PropTypes from "prop-types"

const About = ({ mainContent, title, aboutPics }) => {
  return (
    <section className="bg-darker">
      <div className="container">
        <div className="row">
          <div className="col-sm-6 col-sm-offset-1">
            <div className="feature bordered">
              <h1 className="large uppercase mb64 mb-xs-24">{title}</h1>
              {mainContent && (
                <p className="mb80 mb-xs-24">{mainContent.mainContent}</p>
              )}
              <a className="btn btn-lg btn-filled inner-link" href="about.html">
                Learn More
              </a>
            </div>
          </div>
          <div className="col-sm-5">
            {aboutPics && aboutPics.length > 1 && (
              <>
                <img
                  className="mt80 mt-xs-0 mb24 hidden-xs lazyload"
                  alt={aboutPics[0].title}
                  data-src={aboutPics[0].file.url}
                />
                <img
                  className="col-md-pull-4 relative lazyload"
                  alt={aboutPics[1].title}
                  data-src={aboutPics[1].file.url}
                />
              </>
            )}
          </div>
        </div>
        {/* <!--end of row--> */}
      </div>
      {/* <!--end of container--> */}
    </section>
  )
}
About.propTypes = {
  bioPic: PropTypes.object,
  title: PropTypes.string,
  mainContent: PropTypes.string,
}

export default About
